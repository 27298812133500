import * as React from "react";
import { Box, Typography, Container, Divider } from '@material-ui/core'
import { Page, Layout, Addresses, ErrorComp } from "../components";
import { useAuthData } from '../helpers';
import { Router, useMatch } from '@reach/router'

const User = (props) => {
  const authData = useAuthData();
  console.log('authData', authData)

  React.useEffect(() => {
    if (authData?.isLoggedIn) {
      authData?.getUserDetails();
    }
  }, [authData?.isLoggedIn])
  return (
    <Layout>
      <Page>
        {authData?.isLoggedIn ? <>
          <Container>
            <Box paddingY={4}>
              <Box paddingBottom={1}><Typography variant='h6'>Welcome {authData?.userProfile?.FullName}</Typography></Box>
              <Box paddingBottom={1}><Typography variant='body2'>Email: {authData?.userProfile?.EmailAddress}</Typography></Box>
              <Box paddingBottom={1}><Typography variant='body2'>Mobile Number: {authData?.userProfile?.MobileNumber}</Typography></Box>
            </Box>
            <Divider />
            <Box>
              <Box paddingY={4}>
                <Addresses type='billing' profile />
              </Box>
              <Divider />
              <Box paddingY={4}>
                <Addresses type='shipping' profile />
              </Box>
            </Box>
          </Container>
        </> : <ErrorComp />}

        <Router>

        </Router>
      </Page>
    </Layout>
  )
}

export default User
